<template>
  <div class="modal fade" id="modalSubmitForwardPhrase" tabindex="-1" role="dialog" aria-labelledby="modalSubmitForwardPhraseLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Edite as Frases de Encaminhamento</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body d-flex justify-content-center align-items-center">
					<div class="spinner-border" v-if="isLoading" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<textarea class="textarea-forward-phrase" v-else v-model="phrasesDocument.phrases" />
				</div>
				<div class="modal-footer" v-if="!isLoading">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
					<button type="button" class="btn btn-primary" @click="closeModal" data-dismiss="modal">Salvar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'modalSubmitForwardPhrase',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
    phrasesDocument: {
      type: Object,
      default: {
				phrases: '',
			},
      required: true,
    },
  },
	methods: {
		closeModal() {
			this.$emit('save');
		},
	},
}
</script>

<style scoped>
.textarea-forward-phrase {
	width: 100%;
	resize: none;
	height: 200px;
}
</style>